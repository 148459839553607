import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from '../content/content.service';
import { AppStateService } from '../app-state/app-state.service';
import { DialogClass } from '@resparke/services';

@Injectable({
  providedIn: 'root'
})
export class DialogService extends DialogClass {

  constructor(
    public override dialog: MatDialog,
    public override contentService: ContentService,
    public override appState: AppStateService,
  ) {
    super(dialog, contentService, appState);
  }
}